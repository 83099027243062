import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {catchError, map, take, tap} from 'rxjs/operators';
import { HttpService } from '../services/http.service';
import {Auth} from "../../aplicacao/models/auth.model";
import {BaseProviderService} from "../../services/base-provider.service";
import {EnvioCnds} from "../../aplicacao/models/envioCnds.model";
import {AlertaService} from "../../bibliotecaPjus/services/alerta.service";
import {NgxSpinnerService} from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseProviderService {
  protected module = 'Login';
  constructor(
    protected http: HttpClient,
    private httpService: HttpService,
    protected alertaService: AlertaService,
    private spinner: NgxSpinnerService
  ) {
    super(http);
  }

  httpOptions = {
		headers: new HttpHeaders({
			"Content-Type": "application/json; charset=UTF-8",
      "Accept": "application/json",
      "Access-Control-Allow-Origin": "*"
		})
	};

  login(model: Auth): Observable<Auth> {
    return this.http.post<Auth>(this.getUrl(this.v1, this.module), model, this.httpOptions ).pipe(take(1) ,tap((res: Auth) => {
      this.setAuthToken(res.chave);
      this.setUserName(res.email);
      this.setDataExpiracao(res.dataExpiracao);
      this.setTipoUsuario(res.tipoUsuario);
      this.setParceiroId(res.parceiroId);
      this.setStatusLogin(res.statusLogin);
    }), catchError((e): Observable<any> => {
      if (e.error.key === 'LoginNaoAutorizadoPrimeiroAcesso') {
        this.spinner.hide();
        this.httpService.navigate(['auth/primeiro-acesso/' + model.usuario]);
      } else {
        this.spinner.hide();
        this.alertaService.erro('Usuário ou senha inválidos!');
      }
      return;
    }));
  }

  alterarSenha(model: any): Observable<Auth> {
    return this.http.post<Auth>(this.getUrl(this.v1, this.module, ['alterarSenha']), model, this.httpOptions ).pipe(take(1) ,tap((res: Auth) => {
      this.setAuthToken(res.chave);
      this.setUserName(res.email);
      this.setDataExpiracao(res.dataExpiracao);
      this.setTipoUsuario(res.tipoUsuario);
    }), catchError((e): Observable<any> => {
      if (e.error.key === 'LoginNaoAutorizadoPrimeiroAcesso') {
        this.spinner.hide();
        this.httpService.navigate(['auth/primeiro-acesso/' + model.usuario]);
      } else {
        this.spinner.hide();
        this.alertaService.erro('Usuário ou senha inválidos!');
      }
      return;
    }));
  }

  recuperarSenha(model: any): Observable<Auth> {
    return this.http.post<Auth>(this.getUrl(this.v1, this.module, ['recuperarSenha?usuario=' +  model.usuario]), model).pipe(take(1) ,tap((res: Auth) => {
      this.spinner.hide();
      this.httpService.navigate(['auth/recuperacao-senha/' + model.usuario]);
    }), catchError((e): Observable<any> => {
      this.spinner.hide();
      this.alertaService.erro('Opps, ocorreu um erro!');
      return;
    }));
  }

  recuperacaoSenha(model: any): Observable<Auth> {
    return this.http.post<Auth>(this.getUrl(this.v1, this.module, [`validarRecuperacaoSenha`]), model).pipe(take(1) ,tap((res: Auth) => {
      this.spinner.hide();
      this.httpService.navigate(['auth/']);
    }), catchError((e): Observable<any> => {
      this.spinner.hide();
      this.alertaService.erro('Opps, ocorreu um erro!');
      return;
    }));
  }

  logout(){
    localStorage.clear();
    this.httpService.navigate(['/auth/login']);
  }

  setAuthToken(value:any){
    localStorage.setItem('AuthToken', value);
  }
  getAuthToken(){
    return localStorage.getItem('AuthToken');
  }
  removeAuthToken(){
    return localStorage.removeItem('AuthToken');
  }

  setDataExpiracao(value: any) {
    localStorage.setItem('dataExpiracao', value);
  }
  setTipoUsuario(value: any) {
    localStorage.setItem('tipoUsuario', value);
  }

  setParceiroId(value: any) {
    localStorage.setItem('parceiroId', value);
  }

  setStatusLogin(value: any) {
    localStorage.setItem('statusLogin', value);
  }

  getStatusLogin(){
    return localStorage.getItem('statusLogin');
  }

  getParceiroId(){
    return localStorage.getItem('parceiroId');
  }

  setUserName(value:any){
    localStorage.setItem('user_name', value);
  }
  getUserName(){
    return localStorage.getItem('user_name');
  }

  getTipoUsuario(){
    return localStorage.getItem('tipoUsuario');
  }


}
