import {GenericoTipoEnum} from "../../../../../bibliotecaPjus/enums/enumGenerico.enum";

export enum TipoBoolPreenchido {
  NaoTrabalhado = 'NaoTrabalhado',
  Sim = 'Sim',
  Nao = 'Nao',
}

export class TipoBoolPreenchidoEnum extends GenericoTipoEnum<TipoBoolPreenchido> {
  static readonly NaoTrabalhado = new TipoBoolPreenchidoEnum(TipoBoolPreenchido.NaoTrabalhado, 'Não Trabalhado');
  static readonly Sim = new TipoBoolPreenchidoEnum(TipoBoolPreenchido.Sim, 'Sim');
  static readonly Nao = new TipoBoolPreenchidoEnum(TipoBoolPreenchido.Nao, 'Não');
}
