import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import {AuthService} from "../auth.service";
import {StatusAcessoPlataforma} from "../../../aplicacao/enum/status-acesso-plataforma";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const status = this.authService.getStatusLogin();
          switch (parseInt(status, 0)) {
            case StatusAcessoPlataforma.Sucesso:
              this.router.navigate(['listagem-proposta']);
              break;
            case StatusAcessoPlataforma.CompletarDados:
              const currentUrl = new URL(window.location.href);
              if (!currentUrl.pathname.includes('parceiro-completar-dados')) {
                this.router.navigate(['parceiro-completar-dados']);
              }
              break;
            default:
              break
          }
        }
      })
    );
  }
}
