import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true
    }
  ]
})
export class InputSelectComponent implements ControlValueAccessor, Validator  {
  @Input() id = 'inputId'
  @Input() verKey = false;
  @Input() tipoSelectNumber = true;
  @Input() placeholder = 'Definir placeholder'
  @Input() invalid = false;
  @Input() label = 'Definir Label';
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() campoPreenchidoBooleanValidado = false;
  @Input() list = [];
  @Input() tipoNumber = false;
  @Input() readonly = false;
  @Input() hint;
  @Output() funcaoExterna = new EventEmitter();
  @Output() inputValidado = new EventEmitter();
  @Input() set campoPreenchido(preenchido: boolean) {
    this.campoPreenchidoBoolean = preenchido;
  }
  campoPreenchidoBoolean = false;
  value: string;
  disabled = false;
  onChange: any = (value: any) => { };
  onTouched: any = () => {};
  onFocus(): void {
    this.campoPreenchidoBoolean = false;
    this.inputValidado.emit();
  }
  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value === '') {
      return { required: true };
    }
    return null;
  }

  chamarExterno(value: any): void {
    this.funcaoExterna.emit(value)
  }

  protected readonly parseInt = parseInt;
}
