import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: 'app-input-text-area',
  templateUrl: './input-text-area.component.html',
  styleUrls: ['./input-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextAreaComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputTextAreaComponent),
      multi: true
    }
  ]
})
export class InputTextAreaComponent implements ControlValueAccessor, Validator  {
  @Input() rows: number = 1;
  @Input() bloquearExpansao: boolean = false;
  @Input() label = 'Definir Label';
  @Input() invalid = false;
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() id = 'inputId';
  @Input() placeholder = '';
  @Input() required = true;
  @Input() campoPreenchidoBooleanValidado = false;
  @Input() set campoPreenchido(preenchido: boolean) {
    this.campoPreenchidoBoolean = preenchido;
  }
  @Output() inputValidado = new EventEmitter();
  campoPreenchidoBoolean = false;
  value: string;
  @Input() maxlength: number = 990;
  disabled = false;
  @ViewChild('quilleditor') quilleditor: ElementRef;
  // modules: QuillModules = {
  //   keyboard: {
  //     bindings: {
  //       tab: {
  //         key: 9,
  //         handler: () => {
  //           return false;
  //         }
  //       }
  //     }
  //   }
  // };
  onChange: any = (value: any) => { };
  onTouched: any = () => {};

  onFocus(): void {
    this.campoPreenchidoBoolean = false;
    this.inputValidado.emit();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if(this.required) {
      if (!control.value || control.value === '') {
        return { required: true };
      }
    }
    return null;
  }

  onEditorChanged(event: any): void {
    let atualizandoHtml = null;
    if(event.html) {
      atualizandoHtml = event.html
        .replaceAll("\t", "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;")
        .replace(/<p>/g,  '<p  style="font-size: 10pt !important;">')
        .replace(/<ol>/g, '<ol style="font-size: 10pt !important;">')
        .replace(/<ul>/g, '<ul style="font-size: 10pt !important;">')
        .replace(/<li>/g, '<li style="font-size: 10pt !important;">');
    }
    this.onChange(atualizandoHtml);
  }
}
