import { GenericoTipoEnum } from "../../bibliotecaPjus/enums/enumGenerico.enum";

export enum StatusAcessoPlataforma {
  LoginInvalido = 1,
  CompletarDados = 2,
  Sucesso = 3,
}

export class StatusAcessoPlataformaEnum extends GenericoTipoEnum<StatusAcessoPlataforma> {
  static readonly LoginInvalido = new StatusAcessoPlataformaEnum(StatusAcessoPlataforma.LoginInvalido, 'Login inválido');
  static readonly CompletarDados = new StatusAcessoPlataformaEnum(StatusAcessoPlataforma.CompletarDados, 'Completar dados');
  static readonly Sucesso = new StatusAcessoPlataformaEnum(StatusAcessoPlataforma.Sucesso, 'Sucesso');
}
