<div class="input-group">
    <label class="form-label w-100">
      <div class="row">
        <div class="col d-flex align-items-center">{{label}}</div>
        <div class="col-auto">
          <div class="float-end d-flex">
<!--            <app-btn-lampada [nomeCampo]="id"></app-btn-lampada>-->
<!--            <app-btn-campo-oculto [nomeCampo]="id"></app-btn-campo-oculto>-->
          </div>
        </div>
      </div>
    </label>
    <div class="input-group mt-2px">
      <div class="input-group-prepend" *ngIf="campoPreenchidoBoolean">
        <span class="input-group-text bg-white border-end-0 border-danger" id="basic-addon1" style="padding: 9px 4px 9px 12px;">
          <img src="assets/imgs/icones/robo.svg" alt="">
        </span>
      </div>
      <span class="input-group-text" style="font-size: 0.7rem" id="basic-addon4" *ngIf="!campoPreenchidoBoolean">%</span>
      <input class="form-control"
             type="text"
             [id]="id"
             [value]="formattedValue"
             (change)="inputOnChange($event)"
             (focus)="onFocus()"
             [readOnly]="readonly"
             appDecimalFormat-100
             [ngClass]="{ 'disabled': readonly }"
             [class.is-invalid]="invalidInputAtivo() || campoPreenchidoBoolean"
             [class.sem-axclamacao]="campoPreenchidoBoolean"
             [class.border-start-0]="campoPreenchidoBoolean"
             [class.ps-0]="campoPreenchidoBoolean"
             >
      <div *ngIf="invalidInputAtivo()" class="invalid-feedback">
        <span>Campo obrigatório</span>
      </div>
      <div *ngIf="campoPreenchidoBoolean && campoPreenchidoBooleanValidado" class="invalid-feedback">
        <span>Campo pendente de validação</span>
      </div>
    </div>
  </div>
