import {
  IFiltro,
  IFiltroConstructor,
  IModel,
  IModelConstructor,
  IPesquisa,
  IPesquisaConstructor
} from "./contrato.models";
import {BibliotecaTabelaPaginacao} from "./tabela.models";
import {TipoOrdenacao} from "../enums/enumGenerico.enum";
import {HttpParams} from "@angular/common/http";
/**
 * Classe genérica de Lista paginada
 * @typeparam T Modelo de dado que implementa a interface [[IModel]]
 */
export class ListaPaginada<T extends IModel> {
  data: Array<T>;
  paginaAtual: number;
  pular: number;
  total: number;
  buscar: number;
  quantidadeEmAndamento: number;
  quantidadeTotal: number;
  quantidadeTotalAprovado: number;
  quantidadeTotalPesquisa: number;
  quantidadeTotalReprovado: number;
  /**
   * Construtor da lista paginada
   * @param ctor Definição do construtor de objetos da lista
   * @param lista Objeto de lista
   */
  constructor(ctor: IModelConstructor<T>, lista: any) {
    if (lista != null) {
      this.paginaAtual = (lista.hasOwnProperty('currentPage')) ? lista.paginaAtual : 0;
      this.pular = (lista.hasOwnProperty('skip')) ? lista.pular : 0;
      this.total = (lista.hasOwnProperty('total')) ? lista.total : 0;
      this.buscar = (lista.hasOwnProperty('take')) ? lista.buscar : 20;
      this.quantidadeEmAndamento = (lista.hasOwnProperty('quantidadeEmAndamento')) ? lista.quantidadeEmAndamento : 0;
      this.quantidadeTotal = (lista.hasOwnProperty('quantidadeTotal')) ? lista.quantidadeTotal : 0;
      this.quantidadeTotalAprovado = (lista.hasOwnProperty('quantidadeTotalAprovado')) ? lista.quantidadeTotalAprovado : 0;
      this.quantidadeTotalPesquisa = (lista.hasOwnProperty('quantidadeTotalPesquisa')) ? lista.quantidadeTotalPesquisa : 0;
      this.quantidadeTotalReprovado = (lista.hasOwnProperty('quantidadeTotalReprovado')) ? lista.quantidadeTotalReprovado : 0;

      if (lista.hasOwnProperty('data') && lista.data instanceof Array) {
        this.data = new Array<T>();
        for (const value of lista.data) {
          this.data.push(new ctor(value));
        }
      } else {
        this.data = lista;
      }
    }
  }

  public mapData(key: string = 'id'): Map<number, T> {
    // tslint:disable-next-line: prefer-const
    let map = new Map<number, T>();
    this.data.forEach(x => map.set(x[key], x));
    return map;
  }
}

/**
 * Classe de paginação
 */
export class Paginacao {
  campoOrdenacao: string;
  pular: number;
  buscar: number;
  tipoOrdenacao: TipoOrdenacao;

  /**
   * Construtor da paginação
   * @param other Objeto de paginação ou número da página
   * @param pageSize Sem definição ou número de itens por página
   */
  constructor(other?: any, take: number = 20) {
    if (other != null) {
      if (typeof other === 'object') {
        this.pular = other.pular || 0;
        this.buscar = other.buscar || 20;
        this.tipoOrdenacao = other.tipoOrdenacao || TipoOrdenacao.Crecente;
        this.campoOrdenacao = other.campoOrdenacao || null;
      } else {
        this.pular = other;
        this.buscar = take;
        this.tipoOrdenacao = TipoOrdenacao.Crecente;
        this.campoOrdenacao = '';
      }
    }
  }

  toHttpParams(): HttpParams {
    let params = new HttpParams()
      .set('pular', this.pular.toString())
      .set('buscar', this.buscar.toString());

    if (this.campoOrdenacao != null && this.campoOrdenacao != '') {
      params = params
        .append('campoOrdenacao', this.campoOrdenacao)
        .append('sortOrder', this.campoOrdenacao.toString());
    }
    return params;
  }
}

/**
 * Classe de filtro com paginação
 * @typeparam TFilter Objeto de filtro que implementa a interface [[IFilter]]
 */
export class FiltroPaginacao<TFilter extends IFiltro, TSearch extends IPesquisa> extends Paginacao {
  filtros: TFilter;
  pesquisa: TSearch;

  /**
   * Construtor do filtro com paginação
   * @param ctor Definição do construtor de objetos de filtro
   * @param cstor Definição do construtor de objetos de search
   * @param other Objeto de paginação
   * @param filtros Objeto de filtro
   */
  constructor(ctor: IFiltroConstructor<TFilter>, cstor: IPesquisaConstructor<TSearch>, other?: any, filtros?: any, pesquisa?: any) {
    super(other);

    if (filtros != null) {
      this.filtros = new ctor(filtros);
    }
    if (pesquisa != null) {
      this.pesquisa = new cstor(pesquisa);
    }
  }

}
export class Auditoria {

  constructor(other?: any) {
    if (other != null) {}
  }
}
