import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss']
})
export class InputDateComponent implements OnInit {
  @Input() control: AbstractControl<any>;
  @Input() validarFormulario;
  @Input() label;
  @Input() readonly = false;
  @Input() id;
  @Output() onChange = new EventEmitter();
  @Output() inputValidado = new EventEmitter();
  @Input() campoPreenchidoBooleanValidado = false;
  @Input() comparacaoDataAtiva = false;
  @Input() valorComparacaoData = null;
  @Input() msgAlerta = 'Campo obrigatório';
  @Input() valorComparacaoDataMenor = false;
  @Input() valorComparacaoDataMaior = false;
  @Input() set campoPreenchido(preenchido: boolean) {
    this.campoPreenchidoBoolean = preenchido;
  }
  campoPreenchidoBoolean = false;

  onFocus(): void {
    this.campoPreenchidoBoolean = false;
    this.inputValidado.emit();
  }
  inputOnChange(ev) {
    if(ev.target.value === '') {
      this.control.setValue(null);
    } else {
      this.control.setValue(ev.target.value);
    }
  }

  ngOnInit(): void {}

  removerNumeroString(input: string): string {
    if(input) {
      return input.replace(/^[0-9]+/, '');
    } else {
      return '';
    }
  }

  exibirMsg = null;
  comparacaoData(): string {
    if(this.comparacaoDataAtiva) {
      if(this.control.value > this.valorComparacaoData && this.valorComparacaoDataMenor) {
        return 'Data inicial não pode ser maior que a data final';
      }
      if (this.control.value < this.valorComparacaoData && this.valorComparacaoDataMaior) {
        return 'Data inicial não pode ser maior que a data final';
      }
      return null;
    }
  }
}
