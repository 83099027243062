import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { HttpService } from "../services/http.service";

@Injectable()

export class AuthGuard implements CanActivate {

    constructor(
        private authService: AuthService,
        private httpService: HttpService,
        ){}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) : Observable<boolean> | boolean {
        if(this.authService.getAuthToken()){
            return true;
        }
        this.httpService.navigate(['/auth/login']);
        return false;
    }
}
